.App {
    font-family: Roboto, Arial, sans-serif;
}

.top-menu {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 40px;
    background-color: rgba(0, 177, 255, 0.66);
    border-bottom: 1px solid rgba(0, 71, 102, 0.32);
    padding: 10px;
    column-gap: 10px;
}
.bus-route-label {
    background: transparent;
    color: #ffffff;
    font-size: 14px;
    width: 50px;
    z-index: 1;
    text-align: center;
    padding: 3px 0 3px 3px;
    border: none;
}
.route-id-select{
    font-size: 16px;
    width: 82px;
    z-index: 1;
    text-align: right;
    padding: 3px;
    border: none;
    border-radius: 10px;
    color: #666;
}

.refresh-countdown{
    position: absolute;
    right: 10px;
    background: transparent;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    width: 50px;
    z-index: 1;
    text-align: center;
    padding: 10px 0 3px 15px;
    border: none;
}
.refresh-countdown-label {
    position: absolute;
    top: 2px;
    right: 5px;
    background: transparent;
    color: #ffffff;
    font-size: 12px;
    width: 60px;
    z-index: 1;
    text-align: center;
    padding: 3px 0 3px 15px;
    border: none;
}
.refresh-countdown-label-bottom {
    top: 36px;
}

.refresh-button {
    font-size: 28px;
    width: 50px;
    z-index: 1;
    text-align: center;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 3px;
}

.refresh-icon {
    position: relative;
    top: 3px;
    width: 28px;
    height: 28px;
}

.direction-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: absolute;
    bottom: 24px;
    left: 10px;
    z-index: 1;
    column-gap: 6px;
    row-gap: 6px;
    width: calc(100% - 80px);
}
.get-direction-button {
    font-weight: bold;
    color: #265da9;
    font-size: 14px;
    z-index: 1;
    text-align: center;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 3px 10px;
}
.get-direction-input {
    font-size: 14px;
    min-width: 200px;
    height: 30px;
    z-index: 1;
    text-align: center;
    border: none;
    border-radius: 10px;
    background-color: #ffffff;
    cursor: pointer;
    padding: 3px 6px;
}

/* suggestions goes above input */
.get-direction-input-suggestions {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: calc(100% - 10px);
    max-height: 300px;
    z-index: 1;
    overflow-y: scroll;
    background-color: #ffffff;
    border: 1px solid #999999;
    border-radius: 5px;
    padding: 3px 6px;
    column-gap: 3px;
}
.get-direction-input-suggestions > div {
    padding: 10px 6px;
    cursor: pointer;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px);
    white-space: nowrap;
}
.get-direction-input-suggestions:nth-child(2) {
    bottom: 84px;
}
.get-direction-input-suggestions:last-child {
    bottom: 60px;
    border-radius: 5px 5px 0 0;
}
.get-direction-input-suggestions > div:hover {
    background-color: #eeeeee;
}

.direction-info-block-hide {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    z-index: 1;
    height: max-content;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 3px 6px;
    column-gap: 3px;
    bottom: 114px;
    max-width: 200px;
    opacity: 0;
    transition: all 0.5s ease-in-out;
}
.direction-info-block {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 10px;
    z-index: 1;
    height: max-content;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 3px 6px;
    column-gap: 3px;
    bottom: 114px;
    max-width: 200px;
    transition: all 0.5s ease-in-out;
    opacity: 0.9;
}
.direction-info-block__step {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-basis: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    color: #999999;
    padding: 3px 6px;
}
.direction-info-block__step-route {
    font-size: 14px;
    color: #265da9;
    font-weight: bold;
}
.direction-info-block__step-route-number {
    font-size: 12px;
    color: #265da9;
    font-weight: bold;
}
.direction-info-block__step-route-name {
    font-size: 12px;
    color: #999999;
}
.direction-info-block__step-duration-distance {
    font-size: 12px;
    color: #999999;
}
.direction-info-block__my-location-button {
    position: absolute;
    font-size: 12px;
    color: #265da9;
    font-weight: bold;
    cursor: pointer;
    padding: 3px 6px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    transition: all 0.5s ease-in-out;
    z-index: 1;
    bottom: 75px;
    left: 10px;
}